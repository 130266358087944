import React, { Component } from 'react';

import '../styles/footer.css';

import { Container } from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'


  class Footer extends Component {

    render() {
        return (
        <React.Fragment>

            <div className="footerBottom">
                <Container>
                    <div className="brand">
                    cenmera.com
                    </div>
                    <div className="socials">
                       <FontAwesomeIcon icon={ faFacebook } size="lg" className="white" onClick={()=> window.open("https://www.facebook.com/cenmera/", "_blank")} />
                        <FontAwesomeIcon icon={ faInstagram } size="lg" className="white" onClick={()=> window.open("https://instagram.com/cenmera", "_blank")}   />
                        <FontAwesomeIcon icon={ faTiktok } size="lg" className="white" onClick={()=> window.open("https://www.tiktok.com/@cenmera", "_blank")}/>
                        <FontAwesomeIcon icon={ faEnvelope } size="lg" className="white" onClick={()=> window.open("mailto:hi@cenmera.com", "_blank")} />
                    </div>
                    <div className="copyright">
                        CENMERA © 2021 | ALL RIGHT RESERVED
                    </div>
                </Container>
            </div>
        </React.Fragment>
        );
    }
}

export default Footer;
  