import React, { Component, useState, props } from 'react';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import {
  Collapse
} from "@material-ui/core";

import Silhouette from '../assets/silhouette_2.png';
import Shopee from '../assets/shopee.png';
import Whatsapp from '../assets/whatsapp.png';
import Triangle from '../assets/triangle.png';
import M35_Outline from '../assets/m35_outline.png';
import M35_Yellow from '../assets/m35_yellow.png';
import M35_Green from '../assets/m35_green.png';
import M35_Purple from '../assets/m35_purple.png';
import M35_Red from '../assets/m35_red.png';
import M35_Blue from '../assets/m35_blue.png';
import M35_Pink from '../assets/m35_pink.png';

const products = [
  {
    id: "1",
    productName: "Kodak M35",
    productImage: M35_Outline,
    productVariation: "Colors",
    productPrice: "IDR 344,000",
    productDetails: [
      { id: "1", color: "Sunshine Orange", img: M35_Yellow },
      { id: "2", color: "Lake's Green", img: M35_Green },
      { id: "3", color: "Purple Lilac", img: M35_Purple },
      { id: "4", color: "Retro Red", img: M35_Red },
      { id: "5", color: "Sky Blue", img: M35_Blue },
      { id: "6", color: "Rose Pink", img: M35_Pink },
    ]}
];

class Products extends Component {

  state = { 
    settings: [{ id: "1", open: false }, { id: "2", open: false }]
  };
  handleClick = id => {
    this.setState(state => ({
      ...state,
      settings: state.settings.map(item =>
        item.id === id ? { ...item, open: !item.open } : item
      )
    }));
  };

  changeImg = img => {
    this.setState({
        imgbig: img,
    })
}

handleChange = event => {
  this.setState({
    imgbig: event.target.value,
  })
}


componentDidMount() {
  this.setState({
    imgbig: M35_Yellow,
    selected_color: M35_Outline,
})
}

    render() {
      const { settings } = this.state;
        return (
          <React.Fragment>
            <Container className="products">
                    {products.map(each => (
                    <React.Fragment key={each.id}>
                      <Row className={settings.find(item => item.id === each.id).open
                        ? "hidden"
                        : "visible"}>
                        <Col sm={4} md={5} lg={5}>
                          <img className="product-img" src={each.productImage} alt="product" />
                        </Col>
                        <Col sm={8} md={7} lg={7} className="content">
                          <div className="title">
                            {each.productName}
                          </div>
                          <div className="variation">
                            Variation: {each.productVariation}
                          </div>
                          <div className="price">
                            {each.productPrice}
                          </div>
                        </Col>
                      </Row>
                      <Collapse 
                        in={settings.find(item => item.id === each.id).open}
                        timeout="auto"
                        unmountOnExit>
                        <Row>
                            <Col sm={6} md={6} lg={6} className="productImgs">
                                <div className="top-img">
                                  <img className="img-big" src={this.state.imgbig} />
                                </div>
                                <div className="bot-img">
                                    {each.productDetails.map(details => (
                                        <img key={details.id} className="img-small" onClick={()=>this.changeImg(details.img)}
                                          src={details.img} />
                                    ))}
                                  </div>
                            </Col>
                            <Col sm={6} md={6} lg={6}>  
                            
                          <div className="detailed-specs">
                            <h1>{each.productName}</h1>
                            <h5>{each.productPrice}</h5>
                            <br/>
                            <p>
                            Film Transport: Manual wind and rewind <br/>
                            View Finder: Field: 70% <br/>
                            Power Source: 1AAA - Alkaline <br/>
                            Dimension: 114 x 63 x 35 mm <br/>
                            Weight: 100 gram <br/>
                            Main Plastic Material: ABS <br/>
                            </p>
                            <p>
                            {" Kamera Point and shoot dengan format 35mm. Setiap pembelian Kodak M35, mendapatkan Battery Kodak ukuran AAA, Pouch Kamera dan Sticker lucu tentunya. "}
                            </p>

                          <Form.Group>
                              <Form.Control custom as="select" defaultValue={this.state.imgbig} value={this.state.imgbig} onChange={this.handleChange} Uncontrolled>
                                {each.productDetails.map(details => (
                                  <option value={details.img} >{details.color}</option>
                                ))}
                              </Form.Control>
                          </Form.Group>
                          <br/>
                          <h6>Buy Now: </h6>
                          <Row>
                            <Col className="ta-center">
                              <img src={Shopee}  className="buyNow" onClick={()=> window.open("https://shopee.co.id/cenmera", "_blank")}/>
                              <h5 onClick={()=> window.open("https://shopee.co.id/cenmera", "_blank")}>via Shopee</h5>
                            </Col>
                            <Col className="ta-center">
                              <img src={Whatsapp} className="buyNow" onClick={()=> window.open("wa.me/6287702051998", "_blank")}/>
                              <h5 onClick={()=> window.open("https://wa.me/6287702051998", "_blank")}>via Whatsapp</h5>
                            </Col>
                            <Col className="ta-center">
                            </Col>
                          </Row>
                          </div>
                            </Col>
                        </Row>
                      </Collapse>
                      <Row className="collapseButton">
                        <Col sm={5} md={5} lg={5} xs={5} className="hr">
                        </Col>
                        <Col sm={2} md={2} lg={2} xs={2}>
                          <div className="togglerButton">
                            <img 
                            src={Triangle}
                            onClick={() => this.handleClick(each.id)}
                            className={settings.find(item => item.id === each.id).open
                              ? "expandedImg"
                              : "collapsedImg"}
                            />
                          </div>
                        </Col>
                        <Col sm={5} md={5} lg={5} xs={5} className="hr">
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
        );
    }
}

export default Products;


