import React, { Component } from 'react';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom';


class NotFound extends Component {

    render() {
        return (
          <React.Fragment>
            <Container className="home">
              <div className="button">
                  
                  <Link to={"/"}>
                    go back
                  </Link> 
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
          </React.Fragment>
        );
    }
}

export default NotFound;
