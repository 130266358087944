import React, { Component } from 'react';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router'

import Silhouette from '../assets/silhouette_4.png';

class Blog extends Component {
  state = {
    redirect: false
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }
    render() {
        return (
          this.state.redirect
      ? <Redirect to="/" />
      :
          <React.Fragment>
            <Container className="contact">
              Coming Soon.
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
        );
    }
}

export default Blog;
