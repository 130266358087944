import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import Silhouette from '../assets/silhouette_1.png';

class Home extends Component {

    render() {
        return (
          <React.Fragment>
            <Container className="home">
              <div className="maintenance">
                <p className="ta-left">
                hi, thanks for visiting cenmera.com! <br/>
                unfortunately, our website is still under development. <br/>
                worry not! you can still browse our products through our <a href="https://instagram.com/cenmera" target="_blank">instagram,</a> 
                <a href="https://www.tiktok.com/@cenmera" target="_blank"> tiktok,</a>
                <br/>
                <a href="https://shope.ee/AUSS2MQCAW" target="_blank"> shopee,</a>
                <a href="https://wa.me/62811388022" target="_blank"> or whatsapp @cenmera. </a><br/>
                thank you for understanding, cheers!
                </p>
                <br/>                                                      
                <p className="ta-right">
                -cen
                </p>
              </div>
              <br/>
              <br/>
              <div className="button" >
                <a href="" onClick={()=> this.props.history.push('/contact')}>
                  shop now
                </a>
                {/* <Link to={"/products"}>
                  shop now
                </Link> */}
              </div>
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
    );
    }
}

export default Home;
