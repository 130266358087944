import React, { Component } from 'react';
import '../styles/header.css';

import { Container } from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import {NavLink, Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import NavLogo from '../assets/logo_w_text.png';

class Header extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false,
        };
      }
      
    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    render() {
      const { isOpen } = this.state;
      return (
        <React.Fragment>
            <div className="header">
                <Navbar expand="xl">
                <Container>
                    <Navbar.Brand>
                        <img src={NavLogo} alt="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.toggle} >
                        <FontAwesomeIcon icon={faBars} size="2x"/>
                    </Navbar.Toggle>
                    {/* harusnya disini links */}
                </Container>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto ta-center">
                    <Navbar.Toggle>
                        <NavLink to={"/"} exact={true} className="nav-link" activeClassName={"active"}>home</NavLink>
                        <NavLink to={"/manuals"} className="nav-link">manuals</NavLink>
                        {/* <NavLink to={"/about"} className="nav-link">about</NavLink> */}
                        {/* <NavLink to={"/products"} className="nav-link">products</NavLink> */}
                        <NavLink to={"/contact"} className="nav-link">contact</NavLink>
                        <NavLink to={"/warranty"} className="nav-link">warranty</NavLink>
                        {/* <NavLink to={"/blog"} className="nav-link">blog</NavLink> */}
                    </Navbar.Toggle>
                    </Nav>
                </Navbar.Collapse>  
            </Navbar>
        </div>
        </React.Fragment>
        );
    }
}

export default Header;
  