import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/responsive.css';
import './styles/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Products from './components/Products.js';
import Manuals from './components/Manuals.js';
import Warranty from './components/Warranty.js';
import Contact from './components/Contact.js';
import Blog from './components/Blog.js';
import NotFound from './components/NotFound.js';

import ScrollToTop from './ScrollToTop.js';


// import * as serviceWorker from './serviceWorker';

import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
      <Header/>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route path="/products" component={Products} /> */}
          {/* <Route path="/about" component={About} /> */}
          <Route path="/contact" component={Contact} />
          {/* <Route path="/blog" component={Blog} /> */}
          <Route path="/manuals" component={Manuals} />
          <Route path="/warranty" component={Warranty} />
          <Route path="" component={NotFound} />
        </Switch>
      </ScrollToTop>
      <Footer/>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();