import { useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
// import DIGICEN48 from '../assets/digicen48.png';


function Manuals() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  return (
    <>
    <Container className="manuals">
      <div className='page-full-wrap'>
              PANDUAN CENMERA'S
              <div className='squares' onClick={() => setShow(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  DIGICEN 48
                </div>
              </div>
              <div className='squares' onClick={() => setShow2(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  DIGICEN 50
                </div>
              </div>
              <div className='squares' onClick={() => setShow6(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  DIGICEN 60
                </div>
              </div>
              <div className='squares' onClick={() => setShow7(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  DIGICEN 2K
                </div>
              </div>
              <div className='squares' onClick={() => setShow5(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  HANDYCEN
                </div>
              </div>
              <div className='squares' onClick={() => setShow3(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  KODAK M35/M38
                </div>
              </div>
              <div className='squares' onClick={() => setShow4(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  SHIRO シロ CAM
                </div>
              </div>


              {/* <div className='squares' onClick={() => setShow8(true)}>
                <div className='leftSide'>
                  <img src={''}/>
                </div>
                <div className='rightSide links'>
                  NEW CAMERA
                </div>
              </div> */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            DIGICEN48 TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="853"
          height="480"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7384341806080691461`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            DIGICEN50 TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7383213374244424965`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={() => setShow3(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Kodak M35 / M38 TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7384669798136810758`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show4}
        onHide={() => setShow4(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            SHIRO CAM TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7383917742753074437`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show5}
        onHide={() => setShow5(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            HANDYCEN TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7402848731487276294  `}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show6}
        onHide={() => setShow6(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            DIGICEN60 TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7403268929285524741`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show7}
        onHide={() => setShow7(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            DIGICEN2K TUTORIAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="video-responsive">
        <Row>
          <iframe
          width="100%"
          height="800"
          // no cookie, yes control, autoplay
          src={`https://www.tiktok.com/embed/7402140113716710662`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          />
        </Row>
        </div>
        </Modal.Body>
      </Modal>
      </div>
      </Container>
    </>
  );
}

export default Manuals;