import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import Silhouette from '../assets/silhouette_3.png';
import AboutImg from '../assets/about.png';

class About extends Component {

    render() {
        return (
          <React.Fragment>
            <Container className="about">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img className="about-img" src={AboutImg} alt="background silhouette" />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <p>
                cenmera is an analog photography community born in 2020 during the pandemic.
                having a passion for analog photography, cenmera wishes to become a safe space 
                for analog photographers, from amateurs to professionals.
                <br/>
                <br/>
                {"from discussing photo hunting spots to reviewing camera models, cenmera can't wait to be a part of your analog photography journey! "} 
                &hearts;
                </p>
                <br/>
                <div className="button">
                <a href="https://api.whatsapp.com/send?phone=6287702051998&text=heiiii%2C%20can%20i%20get%20to%20know%20you%20more%3F%20%F0%9F%98%B3%F0%9F%98%B3" target="_blank">
                  get to know
                </a>
              </div>
              </Col>
            </Row>
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
        );
    }
}

export default About;
