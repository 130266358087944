import React, { Component } from 'react';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router'

import Modal from 'react-bootstrap/Modal';

import Silhouette from '../assets/silhouette_4.png';

class Warranty extends Component {
  state = {
    redirect: false
  }

  componentDidMount() {
    // this.id = setTimeout(() => this.setState({ redirect: true }), 3000)
  }

  componentWillUnmount() {
    // clearTimeout(this.id)
  }
    render() {
        return (
          <React.Fragment>
            <Container className="warranty">
                           
            <div className='page-full-wrap'>
            <div className='squares' onClick={()=> window.open("https://forms.gle/x2gijPCwWQUHo7VG7", "_blank")}>
                  DAFTAR GARANSI SEUMUR HIDUP
              </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div>
            <b className='title'>Syarat dan Ketentuan Garansi</b>
            <br/>
            <br/>
            <b className='subtitle'>Cakupan Garansi</b><br/>
            <br/>
            <li>Garansi ini hanya berlaku untuk produk yang dibeli dan didaftarkan di cenmera.</li>
            <li>Garansi berlaku jika pembeli memberikan review berupa rating bintang 5.</li>
            <li>Garansi mencakup kerusakan akibat cacat material atau kesalahan produksi.</li>
            <li>Garansi hanya berlaku bagi pembeli yang memiliki bukti pembelian sah.</li>
            <br/>
            <b className='subtitle'>Masa Berlaku Garansi</b><br/>
            <li>Masa garansi berlaku selama seumur hidup.</li>
            <br/>
            <b className='subtitle'>Kondisi Garansi</b><br/>
            Garansi ini tidak berlaku jika:<br/>
            <li>Kerusakan disebabkan oleh penggunaan yang tidak sesuai dengan petunjuk penggunaan.</li>
            <li>Kerusakan disebabkan oleh kecelakaan, penyalahgunaan, atau pengabaian.</li>
            <li>Perubahan atau modifikasi yang dilakukan oleh pihak selain pusat servis resmi cenmera.</li>
            <li>Penggunaan produk dengan komponen atau aksesori yang tidak direkomendasikan oleh cenmera.</li>
            <li>Kerusakan akibat bencana alam, seperti banjir, gempa bumi, atau petir.</li>
            <br/>
            <b className='subtitle'>Klaim Garansi</b><br/>
            <li>Untuk mengajukan klaim garansi, pelanggan harus menghubungi pusat layanan pelanggan cenmera dengan menyediakan bukti pembelian,
              bukti review bintang 5, dan rincian masalah yang dihadapi.</li>
            <li>Produk yang diklaim harus dikirim ke pusat servis cenmera atau lokasi yang ditunjuk, 
            sesuai dengan instruksi yang diberikan oleh layanan pelanggan.</li>
            <li>Semua biaya pengiriman untuk klaim garansi akan ditanggung oleh pelanggan.</li>
            <b>Perbaikan dan Penggantian</b><br/>
            <li>Jika produk terbukti cacat dalam masa garansi, 
            cenmera akan memperbaiki atau mengganti produk dengan produk yang sama atau setara tanpa biaya tambahan.</li>
            <li>Cenmera berhak menentukan apakah produk akan diperbaiki atau diganti.</li>
            <li>Waktu perbaikan atau penggantian akan bervariasi tergantung ketersediaan suku cadang dan kompleksitas perbaikan.</li>
            <b className='subtitle'>Batasan Tanggung Jawab</b><br/>
            <li>Garansi ini merupakan satu-satunya jaminan yang diberikan, dan cenmera tidak bertanggung jawab atas kerusakan insidental, konsekuensial, atau kerusakan lain yang timbul dari penggunaan produk.</li>
            <li>Cenmera tidak memberikan jaminan tambahan, baik secara tertulis maupun tersirat, 
            selain yang disebutkan dalam syarat dan ketentuan ini.</li>
            <br/>
            <b className='subtitle'>Pembatalan Garansi</b><br/>
            <li>Garansi dapat dibatalkan jika terjadi pelanggaran terhadap ketentuan penggunaan produk atau jika 
            produk telah diperbaiki atau dimodifikasi oleh pihak selain cenmera.</li>
            <br/>
            <b className='subtitle'>Lain-lain</b><br/>
            <li>Syarat dan ketentuan ini dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.</li>
            <li>Syarat dan ketentuan ini tunduk pada hukum yang berlaku di Indonesia.</li>
            </div>
              


            </div>
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
        );
    }
}

export default Warranty;
