import React, { Component } from 'react';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router'

import Silhouette from '../assets/silhouette_4.png';
import Shopee from '../assets/shopee.png';
import Whatsapp from '../assets/whatsapp.png';
import Raggam from '../assets/raggam.jpg';
import Instagram from '../assets/instagram.png';
import Youtube from '../assets/youtube.jpg';
import Tiktok from '../assets/tiktok.png';

class Contact extends Component {
  state = {
    redirect: false
  }

  componentDidMount() {
    // this.id = setTimeout(() => this.setState({ redirect: true }), 3000)
  }

  componentWillUnmount() {
    // clearTimeout(this.id)
  }
    render() {
        return (
          <React.Fragment>
            <Container className="contact">
                            {/* BUY NOW VIA
                            <br/>
                            <br/>
                          <Row>
                            <Col className="ta-center">
                              <img src={Shopee}  className="buyNow" onClick={()=> window.open("https://shopee.co.id/cenmera", "_blank")}/>
                              <h5 onClick={()=> window.open("https://shopee.co.id/cenmera", "_blank")}>Shopee @cenmera</h5>
                            </Col>
                            <Col className="ta-center">
                              <img src={Whatsapp} className="buyNow" onClick={()=> window.open("https://wa.me/6287702051998", "_blank")}/>
                              <h5 onClick={()=> window.open("https://wa.me/6287702051998", "_blank")}>Whatsapp @cenmera</h5>
                            </Col>
                          </Row> */}
            <div className='page-full-wrap'>
              Contact Cenmera:
              <br/>

              <div className='squares' onClick={()=> window.open("https://instagram.com/cenmera", "_blank")}>
                <div className='leftSide'>
                  <img src={Instagram}/>
                </div>
                <div className='rightSide links'>
                  Instagram
                </div>
              </div>

              <div className='squares' onClick={()=> window.open("https://wa.me/62811388022", "_blank")}>
                <div className='leftSide'>
                  <img src={Whatsapp}/>
                </div>
                <div className='rightSide links'>
                  {/* Jual Kamera / Ngobrol² */}
                  Whatsapp
                </div>
              </div>
              
              <div className='squares' onClick={()=> window.open("https://shope.ee/AUSS2MQCAW", "_blank")}>
                <div className='leftSide'>
                  <img src={Shopee}/>
                </div>
                <div className='rightSide links'>
                  Shopee
                </div>
              </div>


              {/* <div className='squares' onClick={()=> window.open("https://www.raggam.id/category/?storeName=cenmera", "_blank")}>
                <div className='leftSide'>
                  <img src={Raggam}/>
                </div>
                <div className='rightSide links'>
                  Sewa Kamera
                </div>
              </div> */}

              <div className='squares' onClick={()=> window.open("https://www.tiktok.com/@cenmera", "_blank")}>
                <div className='leftSide'>
                  <img src={Tiktok}/>
                </div>
                <div className='rightSide links'>
                  TikTok Shop
                </div>
              </div>


              <div className='squares' onClick={()=> this.props.history.push('/manuals')}>
                <div className='leftSide'>
                  {/* <img src={Shopee}/> */}
                </div>
                <div className='rightSide links'>
                  Panduan Manual Kamera
                </div>
              </div>

              <div className='squares' onClick={()=> this.props.history.push('/warranty')}>
                <div className='leftSide'>
                  {/* <img src={Shopee}/> */}
                </div>
                <div className='rightSide links'>
                  DAFTAR GARANSI
                </div>
              </div>

            </div>
            </Container>
            <div className="silhouette">
              <img className="img" src={Silhouette} alt="background silhouette" />
            </div>
          </React.Fragment>
        );
    }
}

export default Contact;
